/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Flex, Box } from 'theme-ui';
import Decimal from 'decimal.js';
import { formatting } from '@accru-finance/shared-ui-libs';
import { Title } from './table';
import Item from './user-data-item';
import labelMapping from '../utils/label-mapping';

const InvestmentTotals = ({ state, product }) => {
    const totals =
        state?.transactionsDataSummary?.account_summary?.mixed_fund_balances?.filter(
            (item) => item.product === product
        ) || [];

    const overview =
        state?.transactionsDataSummary?.account_summary?.mixed_funds_overview?.find(
            (item) => item.product === product
        ) || {};

    const totalCustom = totals.reduce((accum, item) => {
        accum = accum.add(new Decimal(item.amount_custom));
        return accum;
    }, new Decimal(0));

    return (
        <Flex>
            <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
                {totals.map((total) => (
                    <Item
                        smallWidth
                        title={labelMapping(total.currency)}
                        value={`${formatting.formatForDisplay(total.amount)} (${formatting.formatForDisplay(
                            total.amount_custom
                        )} ${state.currency?.toUpperCase()})`}
                    />
                ))}

                <Item
                    smallWidth
                    title={`Total (${state.currency?.toUpperCase()})`}
                    value={`${formatting.formatForDisplay(new Decimal(totalCustom))}`}
                />

                <Item
                    title="Valuation (USDC)"
                    value={`${formatting.formatForDisplay(new Decimal(overview.valuation || 0))}`}
                />

                <Item
                    title="Profit and Loss (USDC)"
                    value={`${formatting.formatForDisplay(new Decimal(overview.pnl_usd || 0))}`}
                    valueColor={new Decimal(overview.pnl_usd || 0).greaterThanOrEqualTo(0) ? 'green' : 'red'}
                />

                <Item
                    title={`Profit and Loss (${state.currency?.toUpperCase()})`}
                    value={`${formatting.formatForDisplay(new Decimal(overview.pnl_custom || 0))}`}
                    valueColor={new Decimal(overview.pnl_custom || 0).greaterThanOrEqualTo(0) ? 'green' : 'red'}
                />
            </Flex>
        </Flex>
    );
};

const WalletData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item
                title="Euro Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'eur'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="GBP Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'gbp'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="BTC Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'bitcoin'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="ETH Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'ethereum'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="FTM Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'ftm'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="CRO Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'cro'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Matic Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'matic'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Ada Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'ada'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Link Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'link'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="AVAX Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'avax'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="pufETH Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'pufeth'
                    )?.amount || ''
                )}`}
            />
        </Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            <Item
                title="USDT Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'tether'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="DAI Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'dai'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="USDC Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'usd-coin'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="USDC Interest Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'usdc-interest'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="DOGE Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'dogecoin'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Uniswap Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'uni'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Curve Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'crv'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Dot Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'dot'
                    )?.amount || ''
                )}`}
            />
            <Item
                title="Sol Wallet"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.deposit_balances?.find(
                        (balance) => balance.currency === 'sol'
                    )?.amount || ''
                )}`}
            />
        </Flex>
    </Flex>
);

const BalanceData = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item
                title={`Total Balance (${state?.currency?.toUpperCase() || 'EUR'})`}
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.custom_balance || ''
                )}`}
            />
            <Item
                title={`Total Uninvested (${state?.currency?.toUpperCase() || 'EUR'})`}
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.total_uninvested_custom || ''
                )}`}
            />
            <Item
                title={`Total Earnings (${state?.currency?.toUpperCase() || 'EUR'})`}
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.total_earnings_custom || ''
                )}`}
            />
            <Item
                title={`Earnings per day (${state?.currency?.toUpperCase() || 'EUR'})`}
                value={`${
                    formatting.formatForDisplay(
                        state.transactionsDataSummary?.account_summary?.earnings_per_hour_custom || 0
                    ) * 24 || ''
                }`}
            />
        </Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column', borderLeft: '1px grey solid', pl: '50px' }}>
            <Item
                title="Maple USDC"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'maple'
                    )?.value || ''
                )}`}
            />
            <Item
                title="Maple ETH"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'maple_eth'
                    )?.value || ''
                )}`}
            />
            <Item
                title="Intero USDC"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'maple_intero'
                    )?.value || ''
                )}`}
            />
            <Item
                title="Stablecoin"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'stablecoin'
                    )?.value || ''
                )}`}
            />
            <Item
                title="Bitcoin"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'auto_btc'
                    )?.value || ''
                )}`}
            />
            <Item
                title="Ethereum"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'auto_eth'
                    )?.value || ''
                )}`}
            />
        </Flex>
    </Flex>
);

const ProfitAndLoss = ({ state }) => (
    <Flex>
        <Flex sx={{ mt: '30px', flexDirection: 'column' }}>
            <Item
                title="Maple USDC"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'maple'
                    )?.profit_and_loss?.custom || ''
                )}`}
                valueColor={
                    new Decimal(
                        state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                            (balance) => balance.type === 'maple'
                        )?.profit_and_loss?.custom || 0
                    ).greaterThanOrEqualTo(new Decimal(0))
                        ? 'green'
                        : 'red'
                }
            />
            <Item
                title="Maple ETH"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'maple_eth'
                    )?.profit_and_loss?.custom || ''
                )}`}
                valueColor={
                    new Decimal(
                        state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                            (balance) => balance.type === 'maple_eth'
                        )?.profit_and_loss?.custom || 0
                    ).greaterThanOrEqualTo(new Decimal(0))
                        ? 'green'
                        : 'red'
                }
            />
            <Item
                title="Intero USDC"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'maple_intero'
                    )?.profit_and_loss?.custom || ''
                )}`}
                valueColor={
                    new Decimal(
                        state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                            (balance) => balance.type === 'maple_intero'
                        )?.profit_and_loss?.custom || 0
                    ).greaterThanOrEqualTo(new Decimal(0))
                        ? 'green'
                        : 'red'
                }
            />
            <Item
                title="Stablecoin"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'stablecoin'
                    )?.profit_and_loss?.custom || ''
                )}`}
                valueColor={
                    new Decimal(
                        state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                            (balance) => balance.type === 'stablecoin'
                        )?.profit_and_loss?.custom || 0
                    ).greaterThanOrEqualTo(new Decimal(0))
                        ? 'green'
                        : 'red'
                }
            />
            <Item
                title="Bitcoin"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'auto_btc'
                    )?.profit_and_loss?.custom || ''
                )}`}
                valueColor={
                    new Decimal(
                        state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                            (balance) => balance.type === 'auto_btc'
                        )?.profit_and_loss?.custom || 0
                    ).greaterThanOrEqualTo(new Decimal(0))
                        ? 'green'
                        : 'red'
                }
            />
            <Item
                title="Ethereum"
                value={`${formatting.formatForDisplay(
                    state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                        (balance) => balance.type === 'auto_eth'
                    )?.profit_and_loss?.custom || ''
                )}`}
                valueColor={
                    new Decimal(
                        state.transactionsDataSummary?.account_summary?.coin_balances?.find(
                            (balance) => balance.type === 'auto_eth'
                        )?.profit_and_loss?.custom || 0
                    ).greaterThanOrEqualTo(new Decimal(0))
                        ? 'green'
                        : 'red'
                }
            />
        </Flex>
    </Flex>
);

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="Balances" state={state} updateState={updateState} showEditIcon={false} />
        <BalanceData state={state} updateState={updateState} />

        <Box sx={{ height: '20px' }} />

        <Title
            title={`Earn Profit And Loss (${state.currency?.toUpperCase()})`}
            state={state}
            updateState={updateState}
            showEditIcon={false}
        />

        <ProfitAndLoss state={state} updateState={updateState} />

        <Box sx={{ height: '20px' }} />

        <Title title="Wallets" state={state} updateState={updateState} showEditIcon={false} />
        <WalletData state={state} updateState={updateState} />

        <Box sx={{ height: '20px' }} />

        <Title title="AQRU Trend Invested Totals" state={state} updateState={updateState} showEditIcon={false} />
        <InvestmentTotals state={state} updateState={updateState} product="aqru_trend" />

        <Box sx={{ height: '20px' }} />

        <Title title="AQRU Ten Invested Totals" state={state} updateState={updateState} showEditIcon={false} />
        <InvestmentTotals state={state} updateState={updateState} product="aqru_10" />
    </Box>
);
