/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import axios from 'axios';
import { Flex, Paragraph, Button, Box, Select } from 'theme-ui';
import FormInput from './form-input';
import Spinner from './spinner';

const validateForm = (currentState) => {
    if (currentState.currency === 'select') {
        return { valid: false, message: 'Please select a currency' };
    }
    if (
        currentState.amount.trim() === '' ||
        isNaN(currentState.amount.trim() || parseFloat(currentState.amount.trim()) < 0)
    ) {
        return { valid: false, message: 'Please enter an amount to credit' };
    }
    if (currentState.depositType === 'select') {
        return { valid: false, message: 'Please select a type' };
    }
    if (currentState.reason.trim() === '') {
        return { valid: false, message: 'Please enter a reason' };
    }
    return { valid: true };
};

const submit = async (currentState, updateState) => {
    const { valid, message } = validateForm(currentState);
    if (!valid) {
        return updateState({ ...currentState, error: message, loading: false });
    }

    updateState({ ...currentState, error: message, loading: true });

    try {
        const { data } = await axios({
            url: process.env.REACT_APP_TRANSACTIONS_API,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'POST',
            data: {
                query: `mutation {
                    creditAccount(input: {
                        user_id: "${currentState.user_id}"
                        currency: "${currentState.currency}"
                        deposit_type: "${currentState.depositType}"
                        currency_type: "${['gbp', 'eur'].includes(currentState.currency) ? 'FIAT' : 'CRYPTO'}"
                        amount: ${currentState.amount}
                        reason: "${currentState.reason.replace(/\n/g, ' ')}"
                    }) { 
                        success
                    }
                }`,
                variables: null,
            },
        });

        if (!data.data.creditAccount.success) {
            return updateState({
                ...currentState,
                loading: false,
                error: 'Unable to credit account at this time',
            });
        }

        return window.location.reload();
    } catch (e) {
        return updateState({ ...currentState, loading: false, error: 'Failed to credit account' });
    }
};

const CreditAccount = (props) => {
    const [currentState, updateState] = useState({
        error: '',
        loading: false,
        description: '',
        success: false,
        user_id: props.userId,
        currency: 'select',
        reason: '',
        depositType: 'select',
        amount: '',
    });

    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                width: 600,
                height: '65px',
                flexDirection: 'column',
                ml: 40,
                mt: 30,
            }}
        >
            {currentState.loading && <Spinner />}

            <Paragraph sx={{ mt: 30, mb: 30, fontWeight: '800' }}>Credit Account</Paragraph>

            <Paragraph sx={{ mb: 10 }}>Currency</Paragraph>

            <Select
                defaultValue={currentState.currency}
                value={currentState.currency}
                onChange={(e) => updateState({ ...currentState, currency: e.target.value })}
                arrow={
                    <Box
                        as="svg"
                        // @ts-ignore
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentcolor"
                        sx={{
                            ml: -28,
                            mt: -18,
                            alignSelf: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                }
                sx={{ mb: 20, width: 300 }}
            >
                <option value="select">Please Select</option>
                <option value="bitcoin">Bitcoin</option>
                <option value="ethereum">Ethereum</option>
                <option value="usd-coin">USDC</option>
                <option value="usdc-interest">USDC Interest</option>
                <option value="tether">USDT</option>
                <option value="dai">DAI</option>
                <option value="pufeth">pufETH</option>
                <option value="gbp">Pounds</option>
                <option value="eur">Euros</option>
            </Select>

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        amount: e.target.value,
                    })
                }
                value={currentState.amount}
                sx={{ width: 200 }}
                label="Amount"
            />

            <Paragraph sx={{ mb: 10, mt: 20 }}>Type</Paragraph>

            <Select
                defaultValue={currentState.depositType}
                value={currentState.depositType}
                onChange={(e) => updateState({ ...currentState, depositType: e.target.value })}
                arrow={
                    <Box
                        as="svg"
                        // @ts-ignore
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentcolor"
                        sx={{
                            ml: -28,
                            mt: -18,
                            alignSelf: 'center',
                            pointerEvents: 'none',
                        }}
                    >
                        <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                    </Box>
                }
                sx={{ mb: 30, width: 300 }}
            >
                <option value="select">Please Select</option>
                <option value="FREE_CREDIT">Free Credit</option>
                <option value="REFERRAL_CREDIT">Referral Credit</option>
                <option value="FROM_USER">Funds received from user</option>
                <option value="REFUND_FROM_FAILED_WITHDRAWAL">Refund from failed withdrawal</option>
            </Select>

            <FormInput
                onChange={(e) =>
                    updateState({
                        ...currentState,
                        reason: e.target.value,
                    })
                }
                value={currentState.reason}
                sx={{ width: 600 }}
                label="Please provide a reason"
            />

            <Button onClick={() => submit(currentState, updateState)} sx={{ width: 170, mt: 20 }}>
                Submit
            </Button>

            {currentState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>}

            {currentState.success && <Paragraph sx={{ color: 'green', mt: 20 }}>Document uploaded</Paragraph>}
        </Flex>
    );
};

export default CreditAccount;
