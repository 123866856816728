const textMapping = {
    stablecoin: 'Stablecoin',
    auto_eth: 'Ethereum',
    auto_btc: 'Bitcoin',
    maple: 'Maple USDC',
    maple_eth: 'Maple ETH',
    maple_intero: 'Intero USDC',
    bonus: 'Bonus',
    savings: 'Savings',
    inheritance: 'Inheritance',
    salary: 'Salary',
    dividends: 'Dividends',
    property_sale: 'Property Sale',
    gift_or_donation: 'Gift/Donation',
    crypto: 'Existing Cryptocurrency',
    loan: 'Loan',
    gbp: 'GBP',
    eur: 'EUR',
    bitcoin: 'BTC',
    pufeth: 'pufETH',
    ethereum: 'ETH',
    dai: 'DAI',
    dogecoin: 'DOGE',
    ftm: 'FTM',
    crv: 'CRV',
    matic: 'MATIC',
    cro: 'CRO',
    sol: 'SOL',
    uni: 'UNI',
    xlm: 'XLM',
    ada: 'ADA',
    dot: 'DOT',
    link: 'LINK',
    tether: 'USDT',
    'usd-coin': 'USDC',
    aqru_trend: 'Trend',
    over_100000: 'Over 100,000',
    not_applicable: 'Not applicable',
    between_1_10000: '1-10,000',
    between_10001_25000: '10,001-25000',
    between_25001_50000: '25,001-50,000',
    between_50001_100000: '50,001-100,000',
    between_1_50000: '1-50,000',
    between_100001_300000: '100,001-300,000',
    between_300001_500000: '300,001-500,000',
    over_500000: 'Over 500,000',
    in_progress: 'In Progress',
    under_investigation: 'Under Investigation',
    sar: 'SAR',
    new: 'New',
    resolved: 'Resolved',
    false_positive: 'False Positive',
    true_positive: 'True Positive',
    potential_match: 'Potential Match',
    no_match: 'No Match',
    not_started: 'Not Started',
    retry_onfido: 'Retry Onfido',
    retry_all: 'Retry All',
    retry_poa: 'Retry Proof Of Address',
    pending: 'Pending',
    verified: 'Verified',
    blocked: 'Blocked',
    rejected: 'Rejected',
    unknown: 'Unknown',
    edd: 'EDD Legacy (Soon to be removed)',
    edd_onboarding: 'EDD Onboarding',
    edd_ongoing: 'EDD Ongoing',
    edd_on_hold: 'EDD On Hold',
    REFERRAL_CREDIT: 'Referral Credit',
    FREE_CREDIT: 'Free Credit',
    FROM_USER: 'User Deposit',
    LIMITED_LIABILITY: 'Limited Liability',
    SOLE_TRADER: 'Sole Trader',
    PARTNERSHIP: 'Partnership',
    PUBLIC_LIMITED_COMPANY: 'Public limited Company',
    JOINT_STOCK_COMPANY: 'Joint Stock Company',
    CHARITY: 'Charity',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    very_high: 'Very High',
    na: 'Not Applicable',
    passed: 'Passed',
    failed: 'Failed',
    escalated: 'Escalated',
    threshold_reached: 'Threshold Reached',
    very_high_risk_country: 'Very High Risk Country',
    suspicious_activity: 'Suspicious activity',
    pep_true_match: 'PEP true match',
};

// @ts-ignore
export default (key: string) => textMapping[key] || key || '';
